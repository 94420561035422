const configs = {
  development: {
    name: "dev",
    api: {
      endpoint: "http://localhost:5005"
    },
    ethexplorer: {
      url: "http://ropsten.etherscan.io"
    },
    eth: {
      rpcendpoint: "https://rpc.xdaichain.com",
      mintercontractaddress: "0x0734cAB1AaC918f988B58A938c47F6602941fD35",
      nftcontractaddress: "0xC3b0E0943A4bC80a15dfd60E15343f691581f254",
    },
  },
  production: {
    name: "prod",
    api: {
      endpoint: "https://api.nft.ava.do"
    },
    ethexplorer: {
      url: "https://blockscout.com/xdai/mainnet"
    },
    eth: {
      rpcendpoint: "https://rpc.xdaichain.com",
      mintercontractaddress: "0x0734cAB1AaC918f988B58A938c47F6602941fD35",
      nftcontractaddress: "0xC3b0E0943A4bC80a15dfd60E15343f691581f254",
    },
  }
};
let config = process.env.REACT_APP_STAGE
  ? configs[process.env.REACT_APP_STAGE]
  : configs.development;

// console.log("REACT_APP_STAGE=", process.env.REACT_APP_STAGE);
// console.log("env=", config.name);

export default {
  ...config
};

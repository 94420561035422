
import React from "react";
import axios from "axios";
import "./Dashboard.css";
import config from "../../../config";
import useWeb3Modal from "../../../hooks/useWeb3Modal";

const Comp = ({ claimData, hash, onMinted }) => {


    const [provider] = useWeb3Modal();
    // const [signError, setSignError] = React.useState();
    const [minting, setMinting] = React.useState(false);
    // const [mintingSuccess, setMintingSuccess] = React.useState(false);
    const [mintingError, setMintingError] = React.useState();

    const [mintAccount, setMintAccount] = React.useState();
    // const [globalError, setGlobalError] = React.useState();



    // the mint account variable shoudl follow along with the selected address  in the wallet
    React.useEffect(() => {
        if (!provider || !provider.provider) return;
        setMintAccount(provider.provider.selectedAddress);
        provider.provider.on("accountsChanged", (accounts) => {
            setMintAccount(provider.provider.selectedAddress);
        });
    }, [provider]);

    if (!claimData || !hash) {
        return null;
    }

    // if (globalError) {
    //     return (
    //         <section className="section hero is-medium">
    //             <div className="hero-body is-vcentered">
    //                 <div className="container has-text-centered">
    //                     <Error />
    //                 </div>
    //             </div>
    //         </section>
    //     );
    // }

    const postSig = async (hash, signature, mintAccount) => {
        return axios.post(`${config.api.endpoint}/mint`, {
            hash: hash,
            signature: signature,
            address: mintAccount
        })
            .then((r) => {
                setMintingError(`Minting request successful.. Your NFT will appear in your wallet shortly! Congratulations!`);
                // loadHash();
            })
            .catch((error) => {
                if (error.response) {
                    switch (error.response.status) {
                        case 403:
                            // wrong sig or so..
                            setMintingError(`Invalid signature -  or still minting`);
                            break;
                        default:
                            setMintingError(`Unknown minting error`);
                    }
                } else {
                }
            });
    }


    const signRequest = async () => {
        setMinting(true);
        const signer = provider.getSigner();
        signer.signMessage(hash).then(async signature => {
            if (!signature) {
                setMinting(false);
                return; // setSignError('No signature received.');
            } else {
                await postSig(hash, signature, mintAccount);
                onMinted();
                setMinting(false);
            }
        }).catch((e) => {
            setMinting(false);
            // setSignError('No signature received.');
            console.log(e);
        });
    };

    if (claimData.minttx) {
        switch (claimData.minttx) {
            case "minting":
                return (
                    <p>...minting your NFT</p>
                )
            default:
                return (
                    <>
                        {/* <h1 className="is-size-1 is-size-4-mobile has-text-centered-mobile has-text-light">
                            You currently have</h1> */}
                        {/* <br /> */}
                        <section className="info-tiles">
                            <div className="tile is-ancestor has-text-centered">
                                <div className="tile is-parent">
                                    <article className="tile is-child box">
                                        {/* <p className="title">1</p> */}
                                        <p className="title">Your AVADO NFT token</p>
                                        <p className="subtitle"><a className="button is-warn" rel="noopener noreferrer" target="_blank" href={`${config.ethexplorer.url}/tx/${claimData.minttx}`}>Show on chain</a></p>
                                        <p>
                                        Your NFT exists on the xDAI chain - and is minted to the wallet address you select.
                                            </p>
                                    </article>
                                    {/* {claimData.link && (
                                        <article className="tile is-child box">
                                            <p className="title">1</p>
                                            <p className="subtitle">HOPR voucher</p>
                                            <p className="subtitle"><a className="button is-warn" target="_blank" href={`${claimData.link}`}>Claim Voucher</a></p>
                                        </article>
                                    )} */}
                                </div>
                            </div>
                        </section >
                    </>
                )
        }
    }

    if (mintingError) {
        return (<p>{mintingError}</p>);
    }

    return (
        <>
            <div className="price has-text-white">Mint your {claimData.model} NFT<br/>
                to address {mintAccount}<br /></div>
            <button disabled={minting}
                className="button is-success is-outlined is-medium"
                onClick={async () => {
                    signRequest();
                }}
            >
                Sign minting request
                </button>
            <p className="danger">{mintingError}</p>
        </>
    )
}

export default Comp;


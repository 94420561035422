// This is a p5.js adaption of the Nodebox script:
// "Aquatics!" by Lieven Menschaert (using Johan Gielis' Superformula equations)
// https://www.nodebox.net/code/index.php/Aquatics
/* jshint esversion: 6 */
import seedrandom from "seedrandom";

export default function sketch(p5) {

    let rng;

    const zoom = 2;
    p5.setup = () => {
        p5.createCanvas(256 * zoom, 256 * zoom);
    }

    p5.myCustomRedrawAccordingToNewPropsHandler = props => {
        // debugger;
        // if (props.seed && props.index) {
        const seedRND = `${props.seed}${props.index}`;
        rng = seedrandom(seedRND);
        console.log(seedRND);

        // }
    };


    p5.draw = () => {
        if (rng) {
            spawn();
        }
        rng = null;
    }

    // console.log(rng());

    function random_(min, max) {
        // return random(min,max);
        let range = max || min;
        let offset = 0;
        if (min && max) {
            range = max - min;
            offset = min;
        }
        return rng() * range + offset;
    }


    function spawn() {
        p5.background("#D7E1FA");
        let fillcolor = p5.color(random_(255),
            random_(255),
            random_(255),
            random_(128, 230));
        // remove bubble and bgcolor arguments for a transparent perimeter
        let aquatic = new Aquatic(p5.width / 2,
            p5.height / 2,
            random_(p5.width / 4, p5.width / 3),
            // p5.width/4,
            fillcolor,
            true, "#D7E1FA");
        aquatic.drawAquatic();
    }


    // function keyPressed() {
    //     if (key === "g" || key === "G") {
    //         spawn();
    //     }

    //     if (key === "s" || key === "S") {
    //         let timestamp = (`${hour()}-${minute()}-${second()}`);
    //         save(timestamp + ".png");
    //     }
    // }


    class Aquatic {

        constructor(x, y, size, fillcolor, bubble, bgcolor) {
            this.x = x;
            this.y = y;
            this.s = size;
            this.f = fillcolor;
            this.r = p5.red(this.f);
            this.g = p5.green(this.f);
            this.b = p5.blue(this.f);
            this.a = p5.alpha(this.f);
            this.bubble = bubble;
            this.bg = bgcolor;
            this.eyelist = [];
            this.currentx = random_(-this.s / 8, this.s / 8);
            this.currenty = random_(-this.s / 8, this.s / 8);
            p5.strokeJoin(p5.ROUND);
        }

        drawIrisPupil(pupilx, pupily, pupilsize) {
            let s = pupilsize / 4 + random_(pupilsize / 3);

            // iris
            if (random_(1) < 0.7) {
                p5.fill(255 - this.r, 255 - this.g, 255 - this.b, this.a / 1.5);
                p5.stroke(255 - this.r, 255 - this.g / 2, 255 - this.b, 140);
            } else {
                p5.fill(this.r * 2, this.g, this.b, this.a / 2);
                p5.stroke(this.r, this.g / 2, this.b, 140);
            }

            p5.strokeWeight(2);
            p5.circle(pupilx, pupily, s * 2);

            // pupil
            p5.fill(1);
            p5.stroke(0);
            p5.strokeWeight(5);
            p5.circle(pupilx, pupily, s / 2);
        }

        drawEyeLid(eyex, eyey, eyesize) {
            p5.fill(this.r, this.g, this.b, random_(200, 240));
            p5.stroke(this.r / 2, this.g / 2, this.b / 2);
            p5.strokeWeight(1);
            p5.arc(eyex, eyey, eyesize * 2, eyesize * 2, p5.PI, p5.TWO_PI, p5.CHORD);
        }

        drawEyes(eyex, eyey, eyesize) {
            p5.stroke(this.r / 2, this.g / 2, this.b / 2, 220);

            // eyelashes
            if (random_(1) > 0.3) {
                p5.strokeWeight(2.5);
                p5.translate(eyex, eyey);
                let rot = 0;
                let eyelashes = p5.int(random_(3, 8));

                for (let eyelash = 0; eyelash < eyelashes; eyelash++) {
                    let random_rot = random_(0.2, 0.7);
                    rot += random_rot;
                    p5.rotate(random_rot);
                    p5.line(0, 0, random_(-eyesize * 2, -eyesize * 1.2), 0);
                }

                p5.rotate(-rot);
                p5.translate(-eyex, -eyey);
            }

            // eye
            p5.fill(255);
            p5.strokeWeight(2);
            p5.circle(eyex, eyey, eyesize * 2);
            this.drawIrisPupil(eyex, eyey, eyesize);
            // eye shine
            p5.fill(255);
            p5.noStroke();
            let shinexy = eyesize / 4;
            let shinesize = eyesize / 2.5;
            p5.circle(eyex - shinexy, eyey - shinexy, shinesize);

            // eyelid
            if (random_(1) > 0.5) {
                this.drawEyeLid(eyex, eyey, eyesize);
            }
        }

        drawHair(hairx, hairy, hairlength, angle) {
            let tipx = p5.cos(angle) * hairlength;
            let tipy = p5.sin(angle) * hairlength;
            p5.curve(hairx - random_(-100, 100), hairy + random_(-100, 100), hairx, hairy,
                tipx + this.currentx, tipy + this.currenty, tipx - random_(-100, 100),
                tipy + random_(-100, 100));
        }

        superShape(m, n1, n2, n3, a, b, radius, start, stop, xoff, yoff,
            xdistort, cw, mode) {
            // https://en.wikipedia.org/wiki/Superformula
            function superShapeVertex(angle) {
                let t1 = p5.pow(p5.abs((1 / a) * p5.cos(angle * m / 4)), n2);
                let t2 = p5.pow(p5.abs((1 / b) * p5.sin(angle * m / 4)), n3);
                let t3 = p5.pow(t1 + t2, 1 / n1);
                let x = (t3 * p5.cos(angle) * xdistort * radius) + xoff;
                let y = (t3 * p5.sin(angle) * radius) + yoff;
                return [x, y];
            }
            // plot supershape clock/counter-clockwise
            // drawing hairs only works with cw=True
            let angle = start;
            let tuftstart = random_(0, p5.PI);
            let tuftend = random_(p5.PI, p5.TWO_PI);

            if (cw) {
                while (angle < stop) {
                    let xy = superShapeVertex(angle);
                    if (mode === "vertex") {
                        p5.vertex(xy[0], xy[1]);
                    }
                    else if (mode === "hair") {
                        p5.noFill();
                        p5.stroke(this.r / 2, this.g / 2, this.b / 2, 200);
                        p5.strokeWeight(0.8);
                        this.drawHair(xy[0], xy[1], radius * random_(1.1, 1.2), angle);
                        if (angle > tuftstart && angle < tuftend) {
                            p5.strokeWeight(2);
                            let hairlength = radius * random_(1.3, 1.5);
                            this.drawHair(xy[0], xy[1], hairlength, angle);
                        }
                    }
                    angle += 0.05;
                }
            }
            else {
                while (angle > stop) {
                    let xy = superShapeVertex(angle);
                    p5.vertex(xy[0], xy[1]);
                    angle -= 0.05;
                }
            }
        }

        drawAquatic() {
            // outline / mouth variables
            // b_ for body; m_ for mouth
            let n1 = random_(1) < 0.5 ? -0.8 - random_(5) : 0.8 + random_(5);
            let n2 = 0.5 + random_(5);
            let ba = random_(0.7, 1.2);
            let bb = 1;
            let bm = p5.int(random_(1, 30));
            let bn3 = 1 + random_(-0.3, 0.3); // variation control
            let ma = random_(0.9, 1.1);
            let mb = random_(0.9, 1.1);
            let mradius = this.s * random_(0.2, 0.4);
            let mxoff = this.s / random_(0.9, 1.1);

            // bubbles
            if (this.bubble) {
                p5.noStroke();
                p5.fill(255, 255, 255);
                let bs = this.s * 0.8;
                p5.circle(this.x + random_(-bs, bs), this.y + random_(-bs, bs), bs);
                p5.circle(this.x + random_(-bs, bs), this.y + random_(-bs, bs), this.s / 2);
            }

            // nucleus
            let rot = random_(-p5.PI, p5.PI);
            let xoff = this.x - this.s / 3 * (random_(1) < 0.5 ? 1 : -1);
            let yoff = this.y + this.s / random_(1.5, 20);
            p5.translate(xoff, yoff);
            p5.rotate(rot);
            p5.fill(this.r / 2, this.g / 2, this.b / 2, 80);
            p5.stroke(this.r / 2, this.g / 2, this.b / 2, 80);
            p5.ellipse(0, 0, this.s / random_(1, 3), this.s / random_(1, 3));
            p5.fill(this.r / 3, this.g / 3, this.b / 3, 120);
            p5.circle(0, 0, this.s / 6);
            p5.rotate(-rot);
            p5.translate(-xoff, -yoff);

            // supershapes
            rot = random_(p5.HALF_PI - 0.3, p5.HALF_PI + 0.3);
            p5.translate(this.x, this.y);
            p5.rotate(rot);
            // ectoplasm
            p5.noFill();
            p5.stroke(255, 255, 255);
            p5.strokeWeight(this.s / 8);
            p5.beginShape();
            this.superShape(bm, n1, n2, bn3, ba, bb, this.s - this.s / 12, 0.5, p5.TWO_PI - 0.5,
                0, 0, 1, true, "vertex");
            p5.endShape();
            // body
            p5.fill(this.r, this.g, this.b, 120);
            p5.stroke(this.r / 2, this.g / 2, this.b / 2, 220);
            p5.strokeWeight(this.s / 12);
            p5.beginShape();
            this.superShape(bm, n1, n2, bn3, ba, bb, this.s, 0.5, p5.TWO_PI - 0.5,
                0, 0, 1, true, "vertex");
            // mouth
            this.superShape(bm, 0.98, 3, bn3, ma, mb, mradius, p5.PI + p5.HALF_PI, p5.HALF_PI,
                mxoff, 0, 1.5, false, "vertex");
            p5.endShape(p5.CLOSE);
            // freckles
            p5.fill(this.r * 1.8, this.g * 1.8, this.b * 1.8, 150);
            p5.noStroke();
            for (let i = 10; i < 200; i++) {
                let freckx = i / this.s * 150 * p5.sin(i * 15) + random_(1, 10);
                let frecky = i / this.s * 150 * p5.cos(i * 15) + random_(1, 10);
                let dotsize = random_(1, 10);
                p5.circle(freckx, frecky, dotsize);
            }
            // characters
            var chars = 's*.~_.)`:;*"-';
            [...chars].forEach(char => {
                p5.fill(this.r / 2, this.g / 2, this.b / 2, 70);
                let play = this.s / 2;
                p5.textSize(random_(play / 3, play / 1.5));
                p5.text(char, random_(-play, play / 2), random_(-play * 1.5, play * 1.5));
            });
            // background-colored mask
            p5.fill(this.bg);
            p5.noStroke();
            p5.beginShape();
            p5.vertex(-p5.width * 2, -p5.height * 2);
            p5.vertex(-p5.width * 2, p5.height * 4);
            p5.vertex(p5.width * 4, p5.height * 4);
            p5.vertex(p5.width * 4, -p5.height * 2);
            p5.beginContour();
            this.superShape(bm, n1, n2, bn3, ba, bb, this.s, 0.5, p5.TWO_PI - 0.5, 0, 0, 1,
                true, "vertex");
            this.superShape(bm, 0.98, 3, bn3, ma, mb, mradius, p5.PI + p5.HALF_PI, p5.HALF_PI,
                mxoff, 0, 1.5, false, "vertex");
            p5.endContour();
            p5.endShape(p5.CLOSE);
            // lips
            p5.noFill();
            p5.stroke(this.r / 2, this.g / 2, this.b / 2);
            p5.strokeWeight(this.s / 12);
            p5.beginShape();
            this.superShape(bm, 0.98, 3, bn3, ma, mb, mradius, p5.PI + p5.HALF_PI, p5.HALF_PI,
                mxoff, 0, 1.5, false, "vertex");
            p5.endShape();
            p5.stroke((this.r + this.g) * 0.8,
                (this.g + this.b) * 0.8,
                (this.b + this.r) * 0.8,
                128);
            p5.strokeWeight(this.s / 22.5);
            p5.beginShape();
            this.superShape(bm, 0.98, 3, bn3, ma, mb, mradius, p5.PI + p5.HALF_PI, p5.HALF_PI,
                mxoff, 0, 1.5, false, "vertex");
            p5.endShape();

            // hairs
            if (random_(1) > 0.3) {
                this.superShape(bm, n1, n2, bn3, ba, bb, this.s, 0.5, p5.TWO_PI - 0.5, 0, 0,
                    1, true, "hair");
            }
            p5.rotate(-rot);
            p5.translate(-this.x, -this.y);
            // eye locations
            let eyex = this.x - this.s - random_(this.s / 10);
            let eyes = 3 + p5.int(random_(10));

            for (let i = 0; i < eyes; i++) {

                if (eyex < this.x + this.s - this.s / 2) {
                    eyex = eyex + random_(-10, 10);
                    eyex += random_(30, 50);
                    let eyey = this.y + random_(-this.s / 1.5, this.s / 5);
                    let eyesize = 8 + random_(this.s / 5);

                    let tup = [eyex, eyey, eyesize];
                    this.eyelist.push(tup);
                }
            }

            this.eyelist.forEach(eye => {
                this.drawEyes(eye[0], eye[1], eye[2]);
            });

            // p5.resizeCanvas(48, 48,false);
        }
    }
};

import React from "react";
import Navigation from "../../../components/Navigation.js";
import axios from "axios";
import "./Dashboard.css";
import config from "../../../config";
import useWeb3Modal from "../../../hooks/useWeb3Modal";
import spinner from "../../../assets/spinner.svg";
import Minter from "./Minter.js";
import ClaimLink from "./ClaimLink.js";

const Comp = ({ match }) => {

    const VIEWSTATES = {
        "init": 0,
        "connectwallet": 1,
        "mintnft": 2,
        "claimlink": 3,
    }

    const hash = match.params.hash;

    const [provider, loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal();
    const [claimData, setClaimData] = React.useState();
    // const [mintAccount, setMintAccount] = React.useState();
    // const [globalError, setGlobalError] = React.useState();
    const [loading, setLoading] = React.useState(true);
    // const [linksAvailable, setLinksAvailable] = React.useState();
    const [viewState, setViewState] = React.useState(VIEWSTATES.init);

    // const getDescription = (type) => {
    //     switch (type) {
    //         case "hopr":
    //             return (
    //                 <>
    //                     <p className="subtitle">vouchers for <strong>HOPR</strong> owners (500 wxHOPR each!)</p>
    //                 </>
    //             )
    //         default:
    //             return (
    //                 <>
    //                     <p className="subtitle">vouchers for <strong>AVADO</strong> owners (50 wxHOPR each)</p>
    //                 </>
    //             )
    //     }
    // }

    // const linksBox = () => {
    //     if (!linksAvailable) return null;
    //     const tiles = linksAvailable.map((i) => {
    //         return (
    //             <div className="tile is-parent">
    //                 <article className="tile is-child box">
    //                     <p className="title">{i.available}</p>
    //                     {getDescription(i.type)}
    //                 </article>
    //             </div>
    //         )

    //     })
    //     return (
    //         <section className="info-tiles">
    //             <div className="tile is-ancestor has-text-centered">
    //                 <div className="tile is-parent">
    //                     <article className="tile is-child box">
    //                         <p className="title">1</p>
    //                         <p className="subtitle">AVADO NFT token for each <strong>AVADO/HOPR</strong> owner</p>
    //                     </article>
    //                 </div>
    //                 {tiles}
    //             </div>
    //         </section>
    //     );
    // }

    const loadHash = () => {
        if (!hash) return;
        setLoading(true);
        axios.get(`${config.api.endpoint}/claim/${hash}`)
            .then((r) => {
                setClaimData(r.data)
            })
            .catch((error) => {
                if (error.response) {
                    switch (error.response.status) {
                        // case 404:
                        //     break;
                        default:
                        // setGlobalError(true);

                    }
                } else {
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    React.useEffect(() => {
        loadHash();
    },[]); // eslint-disable-line react-hooks/exhaustive-deps



    // the mint account variable shoudl follow along with the selected address  in the wallet
    React.useEffect(() => {
        // debugger;
        if (!provider) {
            return setViewState(VIEWSTATES.connectwallet);
        }
        if (!claimData) {
            return setViewState(VIEWSTATES.init);
        }
        if (claimData.minttx === null) {
            return setViewState(VIEWSTATES.mintnft);
        }
        return setViewState(VIEWSTATES.claimlink);

    }, [claimData, provider,VIEWSTATES.connectwallet,VIEWSTATES.mintnft,VIEWSTATES.claimlink,VIEWSTATES.init]);

    function ConnectWalletButton({ provider, loadWeb3Modal, logoutOfWeb3Modal }) {
        if (provider) { return null; }
        return (
            <button
                className="button is-success is-outlined is-medium"
                onClick={async () => {
                    loadWeb3Modal();
                }}
            >
                Connect Wallet
            </button>
        )
    }


    const subTiles = () => {

        switch (viewState) {
            case VIEWSTATES.connectwallet:
                return (
                    <>
                        <p className="is-size-4 is-size-6-mobile has-text-light">
                            Please connect to your wallet first to mint your NFT
                        </p>
                        <ConnectWalletButton provider={provider} loadWeb3Modal={loadWeb3Modal} logoutOfWeb3Modal={logoutOfWeb3Modal} />
                    </>
                )
            case VIEWSTATES.mintnft:
                return (
                    <Minter hash={hash} claimData={claimData} onMinted={loadHash} />
                )
            case VIEWSTATES.claimlink:
                return (
                    <>
                        <Minter hash={hash} claimData={claimData} onMinted={loadHash} />
                        {/* <br />
                        <ClaimLink claimData={claimData} onClaimed={loadHash} /> */}
                    </>
                )
            default:
                

        }
    }

    if (loading) {
        return (
            <section className="is-medium">
                <div className="">
                    <div className="container">
                        <div className="columns is-mobile">
                            <div className="column is-8-desktop is-10 is-offset-1  has-text-centered">
                                <p className="is-size-5 has-text-weight-bold">Loading</p>
                                <div className="spacer"></div>
                                <img alt="spinner" src={spinner} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    return (
        <>
            <Navigation />
            <br />

            <section className="section hero">
                <div className="hero-body">
                    <div className="container has-text-centered">
                        <div className="columns">
                            <div className="column hero-image">
                                <h1 className="is-size-1 is-size-4-mobile has-text-centered-mobile has-text-light">
                                    AVADO NFT app</h1>
                                {subTiles()}
                                {/* {linksBox()} */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};

export default Comp;

import React from "react";
import config from "../../../config";
import useWeb3Modal from "../../../hooks/useWeb3Modal";
import NFTAbi from "../../../assets/nft-abi.json";
import { ethers } from "ethers";
import axios from "axios";
import DayJS from 'react-dayjs';
import "./NFTviewer.css";
import P5Wrapper from 'react-p5-wrapper';
import sketch from "../../../assets/sketch.js";

const Comp = () => {

    const [provider] = useWeb3Modal();

    const [mintAccount, setMintAccount] = React.useState();
    const [tokenData, setTokenData] = React.useState();
    const [large, setLarge] = React.useState(false);

    React.useEffect(() => {
        if (!provider || !provider.provider) return;
        setMintAccount(provider.provider.selectedAddress);
        provider.provider.on("accountsChanged", (accounts) => {
            setMintAccount(provider.provider.selectedAddress);
        });
    }, [provider]);

    const getNFTof = async (address) => {
        try {
            const provider = new ethers.providers.JsonRpcProvider(config.eth.rpcendpoint);
            // const walletWithProvider = new ethers.Wallet(config.eth.signer, provider);
            const nft = new ethers.Contract(config.eth.nftcontractaddress, NFTAbi, provider);

            const tokenId = await nft.tokenOfOwnerByIndex(address, 0);    // big int
            const tokenURI = (await nft.tokenURI(tokenId)).replace("ipfs://", "");
            const index = tokenId.toNumber();
            // const tokenData = (await axios.get(`https://ipfs.web3.party/ipfs/${tokenURI}`)).data;
            setTokenData({
                index: index,
                // data: tokenData,
                uri: tokenURI,
            });
            // setTokenIndex(index+1);

            // debugger;


        } catch (e) {
            console.log(`no NFT for address ${address}`);
        }
        // debugger;
    }


    React.useEffect(() => {
        if (mintAccount) {
            getNFTof(mintAccount);
        }
    }, [mintAccount]);

    if (!tokenData) return null;

    return (

        <div className="container has-text-centered nft">

            <h1 className="is-size-1 is-size-4-mobile has-text-centered-mobile has-text-light">
                Your AVADO NFT</h1>
                <p className="title has-text-light is-6 no-padding">
                    <a rel="noopener noreferrer" target="_blank" href={`https://blockscout.com/xdai/mainnet/tokens/${config.eth.nftcontractaddress}/instance/${tokenData.index}/token-transfers`}>view on blockscout</a>
                </p>

            <div className="card small has-text-centered-mobile has-text-centered has-text-light"><div className="card-image">

                <div className="level">
                    <div className="level-item">
                        <figure onClick={() => { setLarge(!large) }} className={`image ${large ? "large" : "small is-48x48"} `}>
                            <P5Wrapper sketch={sketch} seed={tokenData.uri} index="0" />
                        </figure>
                    </div>
                </div>
                <div className="level">
                    {large && (<>
                        <div className="level-item">
                            <p className="max512">This image was generated algorithmically based of the seed of your NFT stored on the blockchain. So your AVADO NFT is actually a unique piece of art!<br/>
                            Inspired on "Aquatics!" by Lieven Menschaert
                            </p>
                        </div>
                        <hr  className="max512"/>
                    </>)}

                </div>
                <p className="title has-text-light is-4 no-padding">NFT #{tokenData.index}
                </p>

                {/* <p>
                    <span className="title has-text-light is-6">Model: {tokenData.data.model} - purchase date <DayJS format="YYYY-MM-DD">{tokenData.data.genesis}</DayJS></span>
                </p> */}
            </div>
            </div>






        </div>
    );


};

export default Comp;
import React from "react";
import { HashLink as Link } from 'react-router-hash-link';
import logo from "../assets/avado-logo-v1.1.svg";
import config from "../config";
import useWeb3Modal from "../hooks/useWeb3Modal";

const Comp = () => {

    const [provider, loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal();

    React.useEffect(() => {
        var burger = document.querySelector(".burger");
        var nav = document.querySelector("#" + burger.dataset.target);

        burger.addEventListener("click", function () {
            burger.classList.toggle("is-active");
            nav.classList.toggle("is-active");
        });
    }, []);



    function DisconnectWalletButton({ provider, logoutOfWeb3Modal }) {
        if (!provider){
            return (
                <button
                className="button is-success is-outlined is-medium"
                onClick={async () => {
                    loadWeb3Modal();
                }}
            >
                Connect Wallet
            </button>
            )
        };
        return (
            <button
                className="button is-success is-outlined is-medium"
                onClick={async () => {
                    logoutOfWeb3Modal();
                }}
            >
                Disconnect Wallet
            </button >
        )
    }



    return (
        <div>
            <nav
                className="navbar is-transparent"
                role="navigation"
                aria-label="main navigation"
            >
                <div className="navbar-brand">
                    <Link
                        className="navbar-item"
                        to={{
                            pathname: "/"
                        }}
                    >
                        <img
                            src={logo}
                            alt="AVADO: Your personal gateway to the decentralized web"
                            width="240"
                        />
                    </Link>

                    {config.name === "dev" && (
                        <span className="tag is-danger">{config.name}</span>
                    )}

                    <button
                        className="navbar-burger burger"
                        aria-label="menu"
                        aria-expanded="false"
                        data-target="navMenu"
                    >
                        <span aria-hidden="true" />
                        <span aria-hidden="true" />
                        <span aria-hidden="true" />
                    </button>
                </div>
                <div id="navMenu" className="navbar-menu">
                    <div className="navbar-end">
                        {/* <Link
                            smooth
                            className="navbar-item text"
                            to="/#about"
                        >About</Link>
                        <Link
                            smooth
                            className="navbar-item text"
                            to="/#ryo-cloud"
                        >AVADO cloud</Link>
                        <Link
                            smooth
                            className="navbar-item text"
                            to="/#dappstore"
                        >DappStore</Link>


                        <Link
                            smooth
                            className="navbar-item text"
                            to="/#use-cases"
                        >Use cases</Link>
                        <Link
                            smooth
                            className="navbar-item text"
                            to="/#hardware"
                        >Hardware</Link>

                        <Link
                            className="navbar-item text"
                            to="/partner"
                        >Your DApp on AVADO</Link>

                        <Link
                            className="navbar-item text"
                            to="/blog"
                        >Blog</Link> */}



                        <div className="navbar-item">
                            <div className="field is-grouped">
                                <p className="control">
                                    <DisconnectWalletButton provider={provider} loadWeb3Modal={loadWeb3Modal} logoutOfWeb3Modal={logoutOfWeb3Modal} />

                                    {/* <WalletButton provider={provider} loadWeb3Modal={loadWeb3Modal} logoutOfWeb3Modal={logoutOfWeb3Modal} /> */}

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );

}


export default Comp;
import React from "react";
// import axios from "axios";
// import spinner from "../../../assets/spinner.svg";
// // import { RadialChart } from "react-vis";
// // import dayjs from "dayjs";
import config from "../../../config";
// import supportBadge from "../../../assets/badges/chevron-11.svg";
// import Moment from 'react-moment';
// import History from "./History";
import { Formik, Field, ErrorMessage } from "formik";
import Navigation from "../../../components/Navigation.js";
import axios from "axios";
import NFTViewer from "./NFTviewer";

const Comp = () => {

    const [postError, setPostError] = React.useState();
    const [postMessage, setPostMessage] = React.useState();



    const header = () => {
        return (
            <>
                <Navigation />

                <section className="section hero is-medium">
                    <div className="hero-body is-vcentered">
                        <div className="container has-text-centered">

                            <NFTViewer />


                            <div className="columns">
                                <div className="column is-three-fifths hero-image">
                                    <h1 className="is-size-1 is-size-4-mobile has-text-centered-mobile has-text-light">
                                        AVADO NFT minter</h1>
                                    <br />
                                    <p className="is-size-4 is-size-6-mobile has-text-light">
                                        AVADO or HOPR owner?<br />You can mint your personal AVADO NFT token<br />and claim your HOPR airdrop
                                <br />
                                        <b>#RunYourOwnNode</b>
                                    </p>

                                </div>

                                <div className="column is-two-fifths">
                                    <Formik

                                        initialValues={{ email: '' }}
                                        validate={values => {
                                            const errors = {};
                                            if (!values.email) {
                                                errors.email = 'Please fill in your shipping e-mail address';
                                            } else if (
                                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                            ) {
                                                errors.email = 'Invalid email address';
                                            }
                                            return errors;
                                        }}
                                        onSubmit={(values, { setSubmitting }) => {
                                            setSubmitting(true);
                                            setPostMessage(null);
                                            setPostError(null);
                                            axios.post(`${config.api.endpoint}/claim`, {
                                                email: values.email
                                            })
                                                .then((r) => {
                                                    console.log(r);
                                                    setPostMessage(`An E-mail has been sent with further instructions!`);
                                                    setSubmitting(false);
                                                })
                                                .catch((error) => {
                                                    if (error.response) {
                                                        switch (error.response.status) {
                                                            case 404:
                                                                setPostError(`Cannot find your email address`);
                                                                break;
                                                            default:
                                                                setPostError("The server cannot process your order right now. Try again later.");
                                                        }
                                                    } else {
                                                        setPostError("The server cannot be reached right now. Try again later.");
                                                    }
                                                    setSubmitting(false);

                                                });


                                        }}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            isSubmitting,
                                            /* and other goodies */
                                        }) => (
                                            <form className="" onSubmit={handleSubmit}>

                                                <h1 className="is-size-1 is-size-4-mobile has-text-centered-mobile has-text-light">
                                                    Get started !</h1>
                                                {/* <h3 className="has-text-light is-size-3">
                                                    Customer verification
                                                </h3> */}

                                                <h4 className="subtitle  has-text-light">Enter your shipping e-mail address</h4>

                                                {/* <h4 className="subtitle">Customer information</h4> */}

                                                <div className="field is-horizontal">
                                                    {/* <div className="field-label is-normal">
                                                                <label className="label">First name</label>
                                                            </div> */}
                                                    <div className="field-body">
                                                        <div className="field">
                                                            <div className="control">
                                                                <Field
                                                                    className="input"
                                                                    type="text"
                                                                    name="email"
                                                                />
                                                                <ErrorMessage
                                                                    className="help is-danger"
                                                                    name="email"
                                                                    component="div"
                                                                />
                                                                {postError && (
                                                                    <div className="help is-danger">{postError}</div>
                                                                )}
                                                                {postMessage && (
                                                                    <h4 className="subtitle  has-text-light">{postMessage}</h4>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button className="button is-warn" type="submit" disabled={isSubmitting}>Send  verification e-mail</button>
                                            </form>
                                        )}
                                    </Formik>

                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </>
        )
    }

    return header();

};

export default Comp;